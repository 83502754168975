import i18next, * as i18n from "i18next";
import { initReactI18next } from "react-i18next";

import ch from "./locales/ch/translation.json";
import de from "./locales/de/translation.json";
import en from "./locales/en/translation.json";
import es from "./locales/es/translation.json";
import fr from "./locales/fr/translation.json";
import it from "./locales/it/translation.json";

const resources = {
    ch: {
        translation: ch
    },
    de: {
        translation: de
    },
    en: {
        translation: en
    },
    it: {
        translation: it
    },
    fr: {
        translation: fr
    },
    es: {
        translation: es
    }
};
i18n.use(initReactI18next)
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources: resources,
        fallbackLng: import.meta.env.VITE_FALLBACK_LANGUAGE ?? "de",
        debug: import.meta.env.DEV,
        interpolation: {
            escapeValue: false
        }
    });

i18next.on("languageChanged", (lang) => {
    document.documentElement.setAttribute("lang", lang);
});

export default i18n;

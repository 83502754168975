import { useTranslation } from "react-i18next";

import { Button } from "../Button";
import { useRootState } from "../state/StateProvider";

export const PaymentResponseSimulator = () => {
    const { t } = useTranslation();
    const {
        dispatch,
        state: { simulatePaymentResponse }
    } = useRootState("scan");

    return (
        <Button
            className="z-10"
            onClick={() => dispatch({ kind: "togglePaymentSimulator" })}
            label={t("paymentSimulator")
                .concat(": ")
                .concat(t(simulatePaymentResponse ?? "success"))}
        />
    );
};
